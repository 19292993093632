import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import {
  Datepicker,
  Input,
  MobileMarginTopWrap,
  TextArea,
} from 'components/common';
// styles
import { RowStyled } from './styles';

const MainInfo = ({ mainInfoRef, errors }) => {
  const { control } = useFormContext();

  const onInvoiceChange = useCallback(([event]) => {
    if (event?.target?.value) {
      return event.target.value.replace(/[^a-zA-Z0-9-[/]]/g, '');
    }
  }, []);

  const onInputChange = useCallback(([event]) => {
    if (event?.target?.value) {
      return event.target.value;
    }
  }, []);

  return (
    <div ref={mainInfoRef}>
      <RowStyled>
        <Controller
          render={({ field }) => (
            <Input
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              label="Order #"
              isRequired
              placeholder="Order #"
              className="CreateProjectInput"
              error={errors?.invoice?.message}
              width="320px"
              height="30px"
              style={{ marginRight: 7 }}
              onChange={event => field.onChange(onInvoiceChange([event]))}
              maxLength={20}
            />
          )}
          name="invoice"
          control={control}
        />
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <Datepicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Start Date"
                className="startDate"
                error={errors?.startDate?.message}
                style={{ marginRight: 8 }}
              />
            )}
            name="startDate"
            control={control}
          />
        </MobileMarginTopWrap>
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <Datepicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Due Date"
                isRequired
                className="dueDate"
                error={errors?.dueDate?.message}
              />
            )}
            name="dueDate"
            control={control}
          />
        </MobileMarginTopWrap>
      </RowStyled>
      <RowStyled>
        <Controller
          render={({ field }) => (
            <Input
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              label="Account Name"
              isRequired
              placeholder="Account Name"
              className="CreateProjectInput"
              error={errors?.account?.message}
              width="200px"
              height="30px"
              style={{ marginRight: 7 }}
              onChange={event => field.onChange(onInputChange([event]))}
            />
          )}
          name="account"
          control={control}
        />
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <Input
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Customer Name"
                isRequired
                placeholder="Customer Name"
                className="CreateProjectInput"
                error={errors?.customer?.message}
                width="270px"
                height="30px"
                onChange={event => field.onChange(onInputChange([event]))}
              />
            )}
            name="customer"
            control={control}
          />
        </MobileMarginTopWrap>
        <MobileMarginTopWrap style={{ marginLeft: 7 }}>
          <Controller
            render={({ field }) => (
              <Datepicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Estimated Time of Completion"
                isRequired
                className="estimatedTimeOfArrival"
                error={errors?.estimatedTimeOfArrival?.message}
              />
            )}
            name="estimatedTimeOfArrival"
            control={control}
          />
        </MobileMarginTopWrap>
      </RowStyled>
      <RowStyled>
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <Input
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Booking Number"
                placeholder="ex. BK-12345"
                className="CreateProjectInput"
                error={errors?.bookingNumber?.message}
                width="665px"
                height="30px"
                onChange={event => field.onChange(onInputChange([event]))}
              />
            )}
            name="bookingNumber"
            control={control}
          />
        </MobileMarginTopWrap>
      </RowStyled>
      <Controller
        render={({ field }) => (
          <TextArea
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="Notes"
            placeholder="Write your notes.."
            error={errors?.notes?.message}
            style={{ marginTop: 6 }}
            rows={2}
            onChange={event => field.onChange(onInputChange([event]))}
          />
        )}
        name="notes"
        control={control}
      />
    </div>
  );
};

export default MainInfo;
